import React from 'react';
import {Link} from 'react-router-dom';
import { Container,  Row, Col} from 'react-bootstrap';

function HomeBlog() {
  return (
    <section className='home-blog-image-wrapper'>
        <Link to="/blog">
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='home-blog-image'>
                            <div className='home-blog-image-text'>
                                <h3>Latest</h3>
                                <div className="ser-line"></div>
                                <h2>Blog Posts</h2>
                                <button className="btn home-blog-link">Learn More</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Link>
    </section>
  )
}

export default HomeBlog