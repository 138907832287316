import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

function MortgageRefinancing() {
  return (
    <div className='mortgage-refinancing-wrapper'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='mortgage-refinancing-left'>
                        <h2>What Is Home Equity?</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <p>When you take out a mortgage on your home, you repay the lender through smaller payments over a period of years. As you repay the loan, your home equity increases. The amount of equity you have will depend on the current market value of your home minus the amount of any outstanding loans against the property. Unlike other loans, you can borrow as much as you need (up to your limit) whenever you need it without repeating the approval process.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='mortgage-refinancing-right'>
                        <h2>Benefits Of A Home Equity Line Of Credit</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <ul>
                            <li> <FaLongArrowAltRight /> Finance home repairs and renovation </li>
                            <li> <FaLongArrowAltRight /> Debt consolidation </li>
                            <li> <FaLongArrowAltRight /> Investment opportunities </li>
                            <li> <FaLongArrowAltRight /> Cover large expenses like higher education or healthcare </li>
                            <li> <FaLongArrowAltRight /> Borrow funds at lower interest rates with flexible payment options </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MortgageRefinancing