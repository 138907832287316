import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function BlogIntro() {
  return (
    <div className='blog-intro-wrapper'>
      <Container>
        <Row>
          <Col md={12}>
            <div className="blog-intor-text">
              <h1>Blog by Sunlite Mortgage</h1>
              <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BlogIntro;