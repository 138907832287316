import React from 'react';
import {Link} from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

function HomeContact() {
  return (
    <section className='home-contact-section'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='home-contact-text'>
                        <h3>Contact Us</h3>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <h2>Working With Over 100 Mortgage Lenders</h2>
                        <p>Looking to transfer a mortgage, or refinancing an existing mortgage to consolidate debt, our team of mortgage agents can help you find the best mortgage solution.</p>
                          <Link to="/contact-mortgage-specialists-agents-toronto" className="btn btn-home-link">Call Now</Link>     
                    </div>           
                </Col>
                <Col md={6}>
                    <div className='home-contact-image'>
                        <img className='img-fluid' src="../../assets/home-contact-image.png" alt="Mortgage Brokers Toronto" />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default HomeContact