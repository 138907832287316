import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { countryList } from "../../../helpers/countryList";

function ContactForm() {
    return (
        <Form className="contact-page-form">
            <Form.Group className="mb-2" controlId="FirstName">
                <Form.Label>First Name <span className="red-icon">*</span></Form.Label>
                <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="LastName">
                <Form.Label>Last Name <span className="red-icon">*</span></Form.Label>
                <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Email address <span className="red-icon">*</span></Form.Label>
                <Form.Control type="email" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Contact Number <span className="red-icon">*</span></Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Text
                        className="p-0 border-0 shadow-none"
                        id="inputGroup-sizing-default"
                        style={{ width: "100px" }}
                    >
                        <Form.Select aria-label="Default select example">
                            {countryList.map((country, index) => (
                                <option key={index} value={country.name}>
                                    {country.name} {country.code}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup.Text>
                    <Form.Control type="text" />
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2" controlId="Organization">
                <Form.Label>Your Organization</Form.Label>
                <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="Message">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} />
            </Form.Group>
            <Button className="btn-contact-page" type="submit">
                Submit
            </Button>
        </Form>
    );
}

export default ContactForm;
