import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoLocationSharp } from "react-icons/io5";
import { GoMail } from "react-icons/go";
import { WiTime4 } from "react-icons/wi";

function ContactIntro() {
  return (
    <div className='contact-page-section'>
        <Container>
            <div className='about-page-text'>
                <h1>Contact Sunlite Mortgage</h1>
                <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                <h3>Mortgage Specialists Toronto | Mortgage Agents Toronto</h3>
            </div>
            <Row className='contact-page-row'>
                <Col md={3}>
                    <div className="contact-page-icon-list">
                        <div className="contact-page-icon">
                            <IoLocationSharp/>
                        </div>
                        <h3>ADDRESS</h3>
                        <p>1 Concorde Gate, Suite 702, Toronto, ON M3C 3N6</p>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="contact-page-icon-list">
                        <div className="contact-page-icon">
                              <FaPhoneAlt/>
                        </div>
                        <h3>CONTACT NUMBER</h3>
                          <p>Local <a href="tel:(647) 361-8443"> (647) 361-8443 </a></p>
                          <p>LD <a href="tel:(877) 385-6267"> (877) 385-6267 </a></p>
                        <p>Fax (877) 236-0243</p>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="contact-page-icon-list">
                        <div className="contact-page-icon">
                              <GoMail />
                        </div>
                        <h3>EMAIL</h3>
                          <p><a href="mailto:info@sunlitemortgage.com"> info@sunlitemortgage.com </a></p>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="contact-page-icon-list">
                        <div className="contact-page-icon">
                              <WiTime4/>
                        </div>
                          <h3>BUSINESS HOURS</h3>
                          <p>MON - FRI </p>
                          <p>08:30 AM - 07:00 PM</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ContactIntro;