import React from 'react';
import {Link} from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { FaTwitter, FaFacebookSquare,FaLinkedin, FaInstagram, FaYelp } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className='footer-wrapper'>
            <Container>
                <Row>
                    <Col md={3} className="footer-page-link">
                        <h3>LINKS</h3>
                        <Link to="/">Home</Link>
                        <Link to="/about-mortgage-agents-toronto">About</Link>
                        <Link to="/toronto-mortgage-refinance-services">Mortgage Refinance</Link>
                        <Link to="/toronto-home-purchase-mortgage-services">Home Purchase</Link>
                        <Link to="/toronto-self-employed-mortgage-services">Self Employed Mortgage</Link>
                        <Link to="/toronto-home-equity-line-of-credit-services">Home Equity Line of Credit</Link>
                        <Link to="/toronto-investment-property-mortgage-services">Investment Property Mortgage</Link>
                        <Link to="/toronto-reverse-mortgage-services">Reverse Mortgage</Link>
                        <Link to="/blog">Blog</Link>
                        <Link to="/contact-mortgage-specialists-agents-toronto">Contact Us</Link>
                    </Col>
                    <Col md={3} className="footer-social">
                        <h3>FOLLOW US</h3>
                        <ul className="footer-social-icon">
                            <a href="https://www.facebook.com/sunlitemortgage/" target="_blank"> <FaFacebookSquare/></a>
                            <a href="https://twitter.com/sunlitemortgage" target="_blank"> <FaTwitter/></a>
                            <a href="https://www.linkedin.com/sunlitemortgagealliance?_l=en_US" target="_blank"> <FaLinkedin/></a>
                            <a href="https://www.instagram.com/sunlitemortgage/" target="_blank"> <FaInstagram/></a>
                            <a href="https://www.yelp.com/biz/sunlite-mortgage-alliance-north-york" target="_blank"> <FaYelp/></a>
                        </ul>
                    </Col>
                    <Col md={3} className="footer-subscribe">
                        <h3>SUBSCRIBE</h3>
                        <Link to="#" className="btn">JOIN OUR MAILING LIST</Link>
                    </Col>
                    <Col md={3} className="footer-address">
                        <h3>CONTACT US</h3>
                        <Link to="#" className='footer-address-link'>1 Concorde Gate, Suite 702, Toronto, ON M3C 3N6</Link>
                        <a href="tel:(647) 361-8443"> Local (647) 361-8443 </a>
                        <a href="tel:(877) 385-6267"> LD (877) 385-6267 </a>
                        <a href="tel:(877) 236-0243"> Fax (877) 236-0243 </a>
                        <a href="mailto:info@sunlitemortgage.com"> info@sunlitemortgage.com </a>
                        <p>MON - FRI - 08:30 AM - 07:00 PM</p>
                    </Col>
                </Row>
            </Container>
            <div className='footer-wrapper-buttom'>
                <div className='footer-wrapper-buttom-text'>
                    <p>© 2022 <a target="_blank" href="https://sunlitemortgage.ca/"> Sunlite Mortgage. </a> Powered By <a target="_blank" href="http://bongosoftbd.com/">Bongosoft Ltd.</a></p>
                </div>
            </div>
        </div>
    );
}

export default Footer;