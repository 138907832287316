import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ConnectMortgageExperts() {
  return (
    <div className="mortgage-experts-wrapper">
        <Container>
            <Row>
                <Col md={10} className="mortgage-experts-col m-auto">
                    <div className='mortgage-experts-text'>
                        <h2>Find The Best Mortgage Rates For Your Investment</h2>
                        <div class="small-image"><img class="img-fluid" src="../../assets/small-title-border.png" alt="Sunlite Mortgage"/></div>
                          <p>At Sunlite Mortgage Alliance, we help you invest with confidence. We help you gain the most out of your mortgage by negotiating the best rates with multiple lenders on your behalf. As experienced mortgage experts, we evaluate your finances to ensure you have the budget for upfront costs and a smooth approval process. We’ll review every detail and outline personalized solutions that work for your situation. Our mortgage experts take the time to understand your requirements and your current financial situation. They will take into account every detail associated with your purchase to ensure you get the most favorable outcome.</p>
                          <h3>Are you looking to purchase an investment property? Let us help you find the right mortgage fit for your financial needs.</h3>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ConnectMortgageExperts