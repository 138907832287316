import React from 'react';
import {Carousel, Container,  Row, Col} from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

function SliderMortgageSolutions() {
  return (
    <div className='home-carousel-wrapper'>
      <div className='home-carousel-text'>
        <h2>Best Mortgage Solutions across</h2>
        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
      </div>
      <Carousel className='custom-carousel' controls={true} >
      <Carousel.Item>
        <Container>
            <Row>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Ajax </li>
                    <li> <FaLongArrowAltRight/> Amherstburg </li>
                    <li> <FaLongArrowAltRight/> Aurora </li>
                    <li> <FaLongArrowAltRight/> Barrie </li>
                    <li> <FaLongArrowAltRight/> Belleville </li>
                    <li> <FaLongArrowAltRight/> Bracebridge </li>
                    <li> <FaLongArrowAltRight/> Bradford West Gwillimbury </li>
                    <li> <FaLongArrowAltRight/> Brampton </li>
                    <li> <FaLongArrowAltRight/> Brant </li>
                    <li> <FaLongArrowAltRight/> Brantford </li>
                    <li> <FaLongArrowAltRight/> Brockville </li>
                    <li> <FaLongArrowAltRight/> Burlington </li>
                    <li> <FaLongArrowAltRight/> Caledon </li>
                    <li> <FaLongArrowAltRight/> Cambridge </li>
                    <li> <FaLongArrowAltRight/> Central Elgin </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Centre Wellington </li>
                    <li> <FaLongArrowAltRight/> Chatham-Kent </li>
                    <li> <FaLongArrowAltRight/> Clarence-Rockland </li>
                    <li> <FaLongArrowAltRight/> Clarington </li>
                    <li> <FaLongArrowAltRight/> Clearview </li>
                    <li> <FaLongArrowAltRight/> Cobourg </li>
                    <li> <FaLongArrowAltRight/> Collingwood </li>
                    <li> <FaLongArrowAltRight/> Cornwall </li>
                    <li> <FaLongArrowAltRight/> East Gwillimbury </li>
                    <li> <FaLongArrowAltRight/> Essa </li>
                    <li> <FaLongArrowAltRight/> Essex </li>
                    <li> <FaLongArrowAltRight/> Fort Erie </li>
                    <li> <FaLongArrowAltRight/> Georgina </li>
                    <li> <FaLongArrowAltRight/> Gravenhurst </li>
                    <li> <FaLongArrowAltRight/> Greater Napanee </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Greater Sudbury </li>
                    <li> <FaLongArrowAltRight/> Grimsby </li>
                    <li> <FaLongArrowAltRight/> Guelph </li>
                    <li> <FaLongArrowAltRight/> Guelph/Eramosa </li>
                    <li> <FaLongArrowAltRight/> Haldimand County </li>
                    <li> <FaLongArrowAltRight/> Halton Hills </li>
                    <li> <FaLongArrowAltRight/> Hamilton </li>
                    <li> <FaLongArrowAltRight/> Huntsville </li>
                    <li> <FaLongArrowAltRight/> Ingersoll </li>
                    <li> <FaLongArrowAltRight/> Innisfil </li>
                    <li> <FaLongArrowAltRight/> Kawartha Lakes </li>
                    <li> <FaLongArrowAltRight/> Kenora </li>
                    <li> <FaLongArrowAltRight/> Kincardine </li>
                    <li> <FaLongArrowAltRight/> King </li>
                    <li> <FaLongArrowAltRight/> Kingston </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Kingsville </li>
                    <li> <FaLongArrowAltRight/> Kitchener </li>
                    <li> <FaLongArrowAltRight/> Lakeshore </li>
                    <li> <FaLongArrowAltRight/> LaSalle </li>
                    <li> <FaLongArrowAltRight/> Leamington </li>
                    <li> <FaLongArrowAltRight/> Lincoln </li>
                    <li> <FaLongArrowAltRight/> London </li>
                    <li> <FaLongArrowAltRight/> Loyalist </li>
                    <li> <FaLongArrowAltRight/> Markham </li>
                    <li> <FaLongArrowAltRight/> Milton </li>
                    <li> <FaLongArrowAltRight/> Mississauga </li>
                    <li> <FaLongArrowAltRight/> Mississippi Mills </li>
                    <li> <FaLongArrowAltRight/> New Tecumseth </li>
                    <li> <FaLongArrowAltRight/> Newmarket </li>
                    <li> <FaLongArrowAltRight/> Niagara Falls </li>
                  </ul>
                </Col>
            </Row>
        </Container>
      </Carousel.Item>
      <Carousel.Item>
        <Container>
            <Row>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Niagara-on-the-Lake </li>
                    <li> <FaLongArrowAltRight/> Norfolk County </li>
                    <li> <FaLongArrowAltRight/> North Bay </li>
                    <li> <FaLongArrowAltRight/> North Dundas </li>
                    <li> <FaLongArrowAltRight/> North Grenville </li>
                    <li> <FaLongArrowAltRight/> North Perth </li>
                    <li> <FaLongArrowAltRight/> Norwich </li>
                    <li> <FaLongArrowAltRight/> Oakville </li>
                    <li> <FaLongArrowAltRight/> Orangeville </li>
                    <li> <FaLongArrowAltRight/> Orillia </li>
                    <li> <FaLongArrowAltRight/> Oro-Medonte </li>
                    <li> <FaLongArrowAltRight/> Oshawa </li>
                    <li> <FaLongArrowAltRight/> Ottawa </li>
                    <li> <FaLongArrowAltRight/> Owen Sound </li>
                    <li> <FaLongArrowAltRight/> Pelham </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Pembroke </li>
                    <li> <FaLongArrowAltRight/> Perth East </li>
                    <li> <FaLongArrowAltRight/> Petawawa </li>
                    <li> <FaLongArrowAltRight/> Peterborough </li>
                    <li> <FaLongArrowAltRight/> Pickering </li>
                    <li> <FaLongArrowAltRight/> Port Colborne </li>
                    <li> <FaLongArrowAltRight/> Port Hope </li>
                    <li> <FaLongArrowAltRight/> Prince Edward County </li>
                    <li> <FaLongArrowAltRight/> Quinte West </li>
                    <li> <FaLongArrowAltRight/> Richmond Hill </li>
                    <li> <FaLongArrowAltRight/> Russell </li>
                    <li> <FaLongArrowAltRight/> Sarnia </li>
                    <li> <FaLongArrowAltRight/> Saugeen Shores </li>
                    <li> <FaLongArrowAltRight/> Sault Ste. Marie </li>
                    <li> <FaLongArrowAltRight/> Scugog </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Selwyn </li>
                    <li> <FaLongArrowAltRight/> Severn </li>
                    <li> <FaLongArrowAltRight/> South Dundas </li>
                    <li> <FaLongArrowAltRight/> South Frontenac </li>
                    <li> <FaLongArrowAltRight/> South Glengarry </li>
                    <li> <FaLongArrowAltRight/> South Stormont </li>
                    <li> <FaLongArrowAltRight/> Springwater </li>
                    <li> <FaLongArrowAltRight/> St. Catharines </li>
                    <li> <FaLongArrowAltRight/> St. Thomas </li>
                    <li> <FaLongArrowAltRight/> Stratford </li>
                    <li> <FaLongArrowAltRight/> Strathroy-Caradoc </li>
                    <li> <FaLongArrowAltRight/> Tecumseh </li>
                    <li> <FaLongArrowAltRight/> Thames Centre </li>
                    <li> <FaLongArrowAltRight/> The Nation </li>
                    <li> <FaLongArrowAltRight/> Thorold </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Thunder Bay </li>
                    <li> <FaLongArrowAltRight/> Tillsonburg </li>
                    <li> <FaLongArrowAltRight/> Timmins </li>
                    <li> <FaLongArrowAltRight/> Tiny </li>
                    <li> <FaLongArrowAltRight/> Toronto </li>
                    <li> <FaLongArrowAltRight/> Trent Hills </li>
                    <li> <FaLongArrowAltRight/> Uxbridge </li>
                    <li> <FaLongArrowAltRight/> Vaughan </li>
                    <li> <FaLongArrowAltRight/> Wasaga Beach </li>
                    <li> <FaLongArrowAltRight/> Waterloo </li>
                    <li> <FaLongArrowAltRight/> Welland </li>
                    <li> <FaLongArrowAltRight/> Wellesley </li>
                    <li> <FaLongArrowAltRight/> Wellington North </li>
                    <li> <FaLongArrowAltRight/> West Grey </li>
                    <li> <FaLongArrowAltRight/> West Lincoln </li>
                  </ul>
                </Col>
            </Row>
        </Container>
      </Carousel.Item>
      <Carousel.Item>
        <Container>
            <Row>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Pembroke </li>
                    <li> <FaLongArrowAltRight/> Perth East </li>
                    <li> <FaLongArrowAltRight/> Petawawa </li>
                    <li> <FaLongArrowAltRight/> Peterborough </li>
                    <li> <FaLongArrowAltRight/> Pickering </li>
                    <li> <FaLongArrowAltRight/> Port Colborne </li>
                    <li> <FaLongArrowAltRight/> Port Hope </li>
                    <li> <FaLongArrowAltRight/> Prince Edward County </li>
                    <li> <FaLongArrowAltRight/> Quinte West </li>
                    <li> <FaLongArrowAltRight/> Richmond Hill </li>
                    <li> <FaLongArrowAltRight/> Russell </li>
                    <li> <FaLongArrowAltRight/> Sarnia </li>
                    <li> <FaLongArrowAltRight/> Saugeen Shores </li>
                    <li> <FaLongArrowAltRight/> Sault Ste. Marie </li>
                    <li> <FaLongArrowAltRight/> Scugog </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Selwyn </li>
                    <li> <FaLongArrowAltRight/> Severn </li>
                    <li> <FaLongArrowAltRight/> South Dundas </li>
                    <li> <FaLongArrowAltRight/> South Frontenac </li>
                    <li> <FaLongArrowAltRight/> South Glengarry </li>
                    <li> <FaLongArrowAltRight/> South Stormont </li>
                    <li> <FaLongArrowAltRight/> Springwater </li>
                    <li> <FaLongArrowAltRight/> St. Catharines </li>
                    <li> <FaLongArrowAltRight/> St. Thomas </li>
                    <li> <FaLongArrowAltRight/> Stratford </li>
                    <li> <FaLongArrowAltRight/> Strathroy-Caradoc </li>
                    <li> <FaLongArrowAltRight/> Tecumseh </li>
                    <li> <FaLongArrowAltRight/> Thames Centre </li>
                    <li> <FaLongArrowAltRight/> The Nation </li>
                    <li> <FaLongArrowAltRight/> Thorold </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> Thunder Bay </li>
                    <li> <FaLongArrowAltRight/> Tillsonburg </li>
                    <li> <FaLongArrowAltRight/> Timmins </li>
                    <li> <FaLongArrowAltRight/> Tiny </li>
                    <li> <FaLongArrowAltRight/> Toronto </li>
                    <li> <FaLongArrowAltRight/> Trent Hills </li>
                    <li> <FaLongArrowAltRight/> Uxbridge </li>
                    <li> <FaLongArrowAltRight/> Vaughan </li>
                    <li> <FaLongArrowAltRight/> Wasaga Beach </li>
                    <li> <FaLongArrowAltRight/> Waterloo </li>
                    <li> <FaLongArrowAltRight/> Welland </li>
                    <li> <FaLongArrowAltRight/> Wellesley </li>
                    <li> <FaLongArrowAltRight/> Wellington North </li>
                    <li> <FaLongArrowAltRight/> West Grey </li>
                    <li> <FaLongArrowAltRight/> West Lincoln </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <ul className='home-ul-list'>
                    <li> <FaLongArrowAltRight/> West Nipissing </li>
                    <li> <FaLongArrowAltRight/> Whitby </li>
                    <li> <FaLongArrowAltRight/> Whitchurch-Stouffville </li>
                    <li> <FaLongArrowAltRight/> Wilmot </li>
                    <li> <FaLongArrowAltRight/> Windsor </li>
                    <li> <FaLongArrowAltRight/> Woodstock </li>
                    <li> <FaLongArrowAltRight/> Woolwich </li>
                    <li> <FaLongArrowAltRight/> Durham Region </li>
                    <li> <FaLongArrowAltRight/> York Region </li>
                    <li> <FaLongArrowAltRight/> Ontario </li>
                    <li> <FaLongArrowAltRight/> Canada </li>
                  </ul>
                </Col>
            </Row>
        </Container>
      </Carousel.Item>
    </Carousel>
  </div>
  )
}

export default SliderMortgageSolutions