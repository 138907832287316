import React from 'react';
import {Link} from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

function HomeIntro() {
  return (
    <div className='home-about-section'>
        <Container>
            <div className='home-about-text'>
                <h2>Sunlite Mortgage</h2>
                <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                <h3>Mortgage Brokers in Toronto, Ontario</h3>
                <p>Mortgage Refinance, Purchase Plus Improvements, Bad Credit, Construction, Home Equity Loan, Self Employed Mortgage Services & more</p>
                <Link to="/contact-mortgage-specialists-agents-toronto" className="btn btn-home-link">Contact Sunlite Mortgage - Toronto Mortgage Agents / Advisors</Link>
            </div>
            <Row className='home-about-row'>
                <Col md={6}>
                    <div className='home-about-row-image'>
                        <img className='img-fluid' src="../../assets/about.png" alt=" Mortgage Solutions Toronto" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='home-about-row-text'>
                        <h3>About</h3>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <h2>Sunlite Mortgage</h2>
                        <p>Sunlite Mortgage Alliance has been providing customized mortgage solutions to Canadian since 2003 and has seen many changes in the mortgage industry. We work with over 100 mortgage lenders including, banks, credit unions, trust companies, and private lenders to help Canadians...</p>
                        <Link to="/about-mortgage-agents-toronto" className="btn btn-home-link">Read More</Link>     
                    </div>           
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default HomeIntro