import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ConactMap from "../component/contact/Map";
import ContactForm from "../component/contact/Form";
import ContactIntro from "../component/contact/contact-intro";

const ContactPage = () => {

    React.useEffect(() => {
        document.title = "Mortgage Specialists, Agents Toronto | Contact Sunlite Mortgage "
    }, []);

    return (
        <>
            <ContactIntro/>
            <Container className="contact-page-container">
                <Row>
                    <Col md={6}>
                        <ConactMap />
                    </Col>
                    <Col md={6}>
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ContactPage;
