import React from 'react';
import AboutIntor from '../component/about-intro';
import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
import ContactBtn from '../component/contact-btn';
// import Footer from '../component/footer';
// import Header from '../component/header';


const AboutPage = () => {

    React.useEffect(()=>{
       document.title = "About mortgage agents Toronto"
    },[]);

    return (
        <>
            <AboutIntor/>
            <SliderMortgageSolutions/>
            <ContactBtn/>
        </>
    );
}

export default AboutPage;