import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

function MortgageRefinancing() {
  return (
    <div className='mortgage-refinancing-wrapper'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='mortgage-refinancing-left self-mortgage-mortgage-left-text'>
                        <h2>What Is A Self-Employed Mortgage?</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <p>A self-employed mortgage is specifically designed for people that run their own businesses. These mortgages will have different requirements than traditional mortgages to accommodate the needs of self-employed persons. It is best to seek the advice of an experienced mortgage specialist to help you understand the process and get the right mortgage fit.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='mortgage-refinancing-right'>
                        <h2>Finding The Right Mortgage</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                          <p>Sunlite Mortgage Alliance is committed to helping people find the right mortgage financing that aligns with their needs. Buying a home is a huge investment and we want to help you achieve it. We understand the needs of self-employed people and work with a network of lenders to find tailored solutions for your situation. Here are some of the ways we can help you -</p>
                        <ul>
                            <li> <FaLongArrowAltRight /> Find the most competitive rates from multiple lenders </li>
                            <li> <FaLongArrowAltRight /> Get tailored mortgage solutions for your specific needs </li>
                            <li> <FaLongArrowAltRight /> Comprehensive guidance throughout the mortgage process </li>
                            <li> <FaLongArrowAltRight /> Work with experienced and licensed mortgage agents </li>
                            <li> <FaLongArrowAltRight /> Professional advice to understand your options and find the right mortgage fit for your needs </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MortgageRefinancing