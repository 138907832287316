import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function HomePurchaseIntro() {
  return (
    <div className='home-purchase-page-section'>
        <Container>
            <div className='about-page-text'>
                <h1>Reverse Mortgage Services</h1>
                <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                <h3>Mortgage Agents / Advisors in Toronto, Ontario</h3>
            </div>
              <Row className='home-purchase-page-row'>
                <Col md={6}>
                    <div className='home-purchase-page-image'>
                      <img className='img-fluid' src="../../assets/reverse-mortgage-intro.jpg" alt="Self-Employed Mortgage Services" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='home-purchase-page-text'>
                        <h2>Reverse Mortgage</h2>
                          <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <p>A financially secure retirement is the difference between enjoying your golden years and stressing over daily expenses. If you are a homeowner above the age of 55, you could consider a reverse mortgage to help with home repairs, healthcare expenses, and anything else you need. But, before you do, talk to us at Sunlite Mortgage Alliance. We know how reverse mortgages work and will help you understand the benefits, restrictions, and everything in between.We are committed to helping you get the most out of your mortgage with the best rates and professional advice. We’ll sit down with you and explain what you stand to benefit as well as every disadvantage. You will be able to understand your options and make a well-informed decision.</p>
                    </div>           
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default HomePurchaseIntro