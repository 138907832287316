import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function RefinanceMiddleText() {
  return (
    <div className='refinance-middle-wrapper'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='refinance-middle-image'>
                        <img className='img-fluid' src="../../assets/mortgage-refinance-image.jpg" alt="Sunlite Mortgage" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='refinance-middle-text'>
                        <h2>Mortgage Refinance Services</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <p>As a homeowner, you can choose to refinance your property for several reasons - to take advantage of lower interest rates, tap into home equity, or consolidate debt. While refinancing can be highly beneficial, you need to make sure it makes financial sense for your situation. Sometimes, the costs of switching to a new lender can outweigh the benefits of refinancing. Connect with us at Sunlite Mortgage Alliance to understand your options and get the most out of your refinance.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default RefinanceMiddleText