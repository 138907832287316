import React from 'react';
import HomeEquityIntor from '../component/home-equity-line/home-purchase-intro';
import HomeEquityText from '../component/home-equity-line/mortgage-refinancing';
import ConnectMortgageExperts from '../component/home-equity-line/connect-mortgage-experts';
import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
import ContactBtn from '../component/contact-btn';
// import Footer from '../component/footer';
// import Header from '../component/header';


const HomeEquityLine = () => {

    React.useEffect(()=>{
        document.title = "Home Equity Line of Credit"
    },[]);

    return (
        <>
            <HomeEquityIntor />
            <HomeEquityText />
            <ConnectMortgageExperts />
            <SliderMortgageSolutions/>
            <ContactBtn/>
        </>
    );
}

export default HomeEquityLine;