import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

function MortgageRefinancing() {
  return (
    <div className='mortgage-refinancing-wrapper'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='mortgage-refinancing-left'>
                        <h2>What Is A Reverse Mortgage?</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <p>With a reverse mortgage, you borrow funds from a lender against a portion of your home equity. Unlike a regular mortgage, where you make regular payments to the lender, the lender pays you. You do not have to sell your home and can continue to live there. The amount you can borrow depends on factors like age, home value, and the lender. The loan does not have to be repaid until the last borrower vacates the property, sells the home, or passes away. When this happens, either the heirs of the home can repay the lender to keep the home or the home is sold to repay the loan. The proceeds go to the homeowner’s estate if the house sells for more than the loan amount and its associated costs.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='mortgage-refinancing-right'>
                        <h2>Homeowners Usually Consider A Reverse Mortgage For The Following Reasons</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <ul>
                           <li> <FaLongArrowAltRight /> Cover medical / healthcare expenses </li>
                           <li> <FaLongArrowAltRight /> Repay any outstanding debts </li>
                           <li> <FaLongArrowAltRight /> Home renovation and repairs </li>
                           <li> <FaLongArrowAltRight /> Create a stream of income for any other expenses </li>
                           <li> <FaLongArrowAltRight /> You still own and live in your home while reaping the benefits</li>
                           <li> <FaLongArrowAltRight /> You do not have to pay tax on the money borrowed </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MortgageRefinancing