import React from 'react';
import RefinanceIntor from '../component/refinance-intro';
import RefinanceMiddleText from '../component/refinance-middle-text';
import MortgageRefinancing from '../component/mortgage-refinancing';
import ConnectMortgageExperts from '../component/connect-mortgage-experts';
import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
import ContactBtn from '../component/contact-btn';
// import Footer from '../component/footer';
// import Header from '../component/header';


const MortgageRefinance = () => {
    React.useEffect(()=>{
       document.title = "Toronto mortgage services"
    },[]);
    return (
        <>
            <RefinanceIntor/>
            <RefinanceMiddleText/>
            <MortgageRefinancing/>
            <ConnectMortgageExperts/>
            <SliderMortgageSolutions/>
            <ContactBtn/>
        </>
    );
}

export default MortgageRefinance;