export const countryList = [
    {
        id: 1,
        name: "Afghanistan",
        iso2: "AF",
        code: "+93",
    },
    {
        id: 2,
        name: "Åland Islands",
        iso2: "AX",
        code: "+358",
    },
    {
        id: 3,
        name: "Albania",
        iso2: "AL",
        code: "+355",
    },
    {
        id: 4,
        name: "Algeria",
        iso2: "DZ",
        code: "+213",
    },
    {
        id: 5,
        name: "American Samoa",
        iso2: "AS",
        code: "+1 684",
    },
    {
        id: 6,
        name: "Andorra",
        iso2: "AD",
        code: "+376",
    },
    {
        id: 7,
        name: "Angola",
        iso2: "AO",
        code: "+244",
    },
    {
        id: 8,
        name: "Anguilla",
        iso2: "AI",
        code: "+1 264",
    },
    {
        id: 9,
        name: "Antarctica",
        iso2: "AQ",
        code: "+672",
    },
    {
        id: 10,
        name: "Antigua and Barbuda",
        iso2: "AG",
        code: "+1 268",
    },
    {
        id: 11,
        name: "Argentina",
        iso2: "AR",
        code: "+54",
    },
    {
        id: 12,
        name: "Armenia",
        iso2: "AM",
        code: "+374",
    },
    {
        id: 13,
        name: "Aruba",
        iso2: "AW",
        code: "+297",
    },
    {
        id: 14,
        name: "Australia",
        iso2: "AU",
        code: "+61",
    },
    {
        id: 15,
        name: "Austria",
        iso2: "AT",
        code: "+43",
    },
    {
        id: 16,
        name: "Azerbaijan",
        iso2: "AZ",
        code: "+994",
    },
    {
        id: 17,
        name: "Bahamas",
        iso2: "BS",
        code: "+1 242",
    },
    {
        id: 18,
        name: "Bahrain",
        iso2: "BH",
        code: "+973",
    },
    {
        id: 19,
        name: "Bangladesh",
        iso2: "BD",
        code: "+880",
    },
    {
        id: 20,
        name: "Barbados",
        iso2: "BB",
        code: "+1 246",
    },
    {
        id: 21,
        name: "Belarus",
        iso2: "BY",
        code: "+375",
    },
    {
        id: 22,
        name: "Belgium",
        iso2: "BE",
        code: "+32",
    },
    {
        id: 23,
        name: "Belize",
        iso2: "BZ",
        code: "+501",
    },
    {
        id: 24,
        name: "Benin",
        iso2: "BJ",
        code: "+229",
    },
    {
        id: 25,
        name: "Bermuda",
        iso2: "BM",
        code: "+1 441",
    },
    {
        id: 26,
        name: "Bhutan",
        iso2: "BT",
        code: "+975",
    },
    {
        id: 27,
        name: "Bolivia, Plurinational State of bolivia",
        iso2: "BO",
        code: "+591",
    },
    {
        id: 28,
        name: "Bosnia and Herzegovina",
        iso2: "BA",
        code: "+387",
    },
    {
        id: 29,
        name: "Botswana",
        iso2: "BW",
        code: "+267",
    },
    {
        id: 30,
        name: "Bouvet Island",
        iso2: "BV",
        code: "+",
    },
    {
        id: 31,
        name: "Brazil",
        iso2: "BR",
        code: "+55",
    },
    {
        id: 32,
        name: "British Indian Ocean Territory",
        iso2: "IO",
        code: "+246",
    },
    {
        id: 33,
        name: "Brunei Darussalam",
        iso2: "BN",
        code: "+673",
    },
    {
        id: 34,
        name: "Bulgaria",
        iso2: "BG",
        code: "+359",
    },
    {
        id: 35,
        name: "Burkina Faso",
        iso2: "BF",
        code: "+226",
    },
    {
        id: 36,
        name: "Burundi",
        iso2: "BI",
        code: "+257",
    },
    {
        id: 37,
        name: "Cambodia",
        iso2: "KH",
        code: "+855",
    },
    {
        id: 38,
        name: "Cameroon",
        iso2: "CM",
        code: "+237",
    },
    {
        id: 39,
        name: "Canada",
        iso2: "CA",
        code: "+1",
    },
    {
        id: 40,
        name: "Cape Verde",
        iso2: "CV",
        code: "+238",
    },
    {
        id: 41,
        name: "Cayman Islands",
        iso2: "KY",
        code: "+1 345",
    },
    {
        id: 42,
        name: "Central African Republic",
        iso2: "CF",
        code: "+236",
    },
    {
        id: 43,
        name: "Chad",
        iso2: "TD",
        code: "+235",
    },
    {
        id: 44,
        name: "Chile",
        iso2: "CL",
        code: "+56",
    },
    {
        id: 45,
        name: "China",
        iso2: "CN",
        code: "+86",
    },
    {
        id: 46,
        name: "Christmas Island",
        iso2: "CX",
        code: "+61",
    },
    {
        id: 47,
        name: "Cocos (Keeling) Islands",
        iso2: "CC",
        code: "+61",
    },
    {
        id: 48,
        name: "Colombia",
        iso2: "CO",
        code: "+57",
    },
    {
        id: 49,
        name: "Comoros",
        iso2: "KM",
        code: "+269",
    },
    {
        id: 50,
        name: "Congo",
        iso2: "CG",
        code: "+242",
    },
    {
        id: 51,
        name: "Congo, The Democratic Republic of the Congo",
        iso2: "CD",
        code: "+243",
    },
    {
        id: 52,
        name: "Cook Islands",
        iso2: "CK",
        code: "+682",
    },
    {
        id: 53,
        name: "Costa Rica",
        iso2: "CR",
        code: "+506",
    },
    {
        id: 54,
        name: "Cote d'Ivoire",
        iso2: "CI",
        code: "+225",
    },
    {
        id: 55,
        name: "Croatia",
        iso2: "HR",
        code: "+385",
    },
    {
        id: 56,
        name: "Cuba",
        iso2: "CU",
        code: "+53",
    },
    {
        id: 57,
        name: "Cyprus",
        iso2: "CY",
        code: "+357",
    },
    {
        id: 58,
        name: "Czech Republic",
        iso2: "CZ",
        code: "+420",
    },
    {
        id: 59,
        name: "Denmark",
        iso2: "DK",
        code: "+45",
    },
    {
        id: 60,
        name: "Djibouti",
        iso2: "DJ",
        code: "+253",
    },
    {
        id: 61,
        name: "Dominica",
        iso2: "DM",
        code: "+1 767",
    },
    {
        id: 62,
        name: "Dominican Republic",
        iso2: "DO",
        code: "+1 809",
    },
    {
        id: 63,
        name: "Ecuador",
        iso2: "EC",
        code: "+593",
    },
    {
        id: 64,
        name: "Egypt",
        iso2: "EG",
        code: "+20",
    },
    {
        id: 65,
        name: "El Salvador",
        iso2: "SV",
        code: "+503",
    },
    {
        id: 66,
        name: "Equatorial Guinea",
        iso2: "GQ",
        code: "+240",
    },
    {
        id: 67,
        name: "Eritrea",
        iso2: "ER",
        code: "+291",
    },
    {
        id: 68,
        name: "Estonia",
        iso2: "EE",
        code: "+372",
    },
    {
        id: 69,
        name: "Ethiopia",
        iso2: "ET",
        code: "+251",
    },
    {
        id: 70,
        name: "Falkland Islands (Malvinas)",
        iso2: "FK",
        code: "+500",
    },
    {
        id: 71,
        name: "Faroe Islands",
        iso2: "FO",
        code: "+298",
    },
    {
        id: 72,
        name: "Fiji",
        iso2: "FJ",
        code: "+679",
    },
    {
        id: 73,
        name: "Finland",
        iso2: "FI",
        code: "+358",
    },
    {
        id: 74,
        name: "France",
        iso2: "FR",
        code: "+33",
    },
    {
        id: 75,
        name: "French Guiana",
        iso2: "GF",
        code: "+44",
    },
    {
        id: 76,
        name: "French Polynesia",
        iso2: "PF",
        code: "+689",
    },
    {
        id: 77,
        name: "French Southern Territories",
        iso2: "TF",
        code: "+",
    },
    {
        id: 78,
        name: "Gabon",
        iso2: "GA",
        code: "+44",
    },
    {
        id: 79,
        name: "Gambia",
        iso2: "GM",
        code: "+220",
    },
    {
        id: 80,
        name: "Georgia",
        iso2: "GE",
        code: "+594",
    },
    {
        id: 81,
        name: "Germany",
        iso2: "DE",
        code: "+49",
    },
    {
        id: 82,
        name: "Ghana",
        iso2: "GH",
        code: "+233",
    },
    {
        id: 83,
        name: "Gibraltar",
        iso2: "GI",
        code: "+350",
    },
    {
        id: 84,
        name: "Greece",
        iso2: "GR",
        code: "+30",
    },
    {
        id: 85,
        name: "Greenland",
        iso2: "GL",
        code: "+299",
    },
    {
        id: 86,
        name: "Grenada",
        iso2: "GD",
        code: "+995",
    },
    {
        id: 87,
        name: "Guadeloupe",
        iso2: "GP",
        code: "+590",
    },
    {
        id: 88,
        name: "Guam",
        iso2: "GU",
        code: "+1 671",
    },
    {
        id: 89,
        name: "Guatemala",
        iso2: "GT",
        code: "+502",
    },
    {
        id: 90,
        name: "Guernsey",
        iso2: "GG",
        code: "+",
    },
    {
        id: 91,
        name: "Guinea",
        iso2: "GN",
        code: "+224",
    },
    {
        id: 92,
        name: "Guinea-Bissau",
        iso2: "GW",
        code: "+245",
    },
    {
        id: 93,
        name: "Guyana",
        iso2: "GY",
        code: "+592",
    },
    {
        id: 94,
        name: "Haiti",
        iso2: "HT",
        code: "+509",
    },
    {
        id: 95,
        name: "Heard Island and Mcdonald Islands",
        iso2: "HM",
        code: "+",
    },
    {
        id: 96,
        name: "Holy See (Vatican City State)",
        iso2: "VA",
        code: "+379",
    },
    {
        id: 97,
        name: "Honduras",
        iso2: "HN",
        code: "+504",
    },
    {
        id: 98,
        name: "Hong Kong",
        iso2: "HK",
        code: "+852",
    },
    {
        id: 99,
        name: "Hungary",
        iso2: "HU",
        code: "+36",
    },
    {
        id: 100,
        name: "Iceland",
        iso2: "IS",
        code: "+354",
    },
    {
        id: 101,
        name: "India",
        iso2: "IN",
        code: "+91",
    },
    {
        id: 102,
        name: "Indonesia",
        iso2: "ID",
        code: "+62",
    },
    {
        id: 103,
        name: "Iran, Islamic Republic of Persian Gulf",
        iso2: "IR",
        code: "+98",
    },
    {
        id: 104,
        name: "Iraq",
        iso2: "IQ",
        code: "+964",
    },
    {
        id: 105,
        name: "Ireland",
        iso2: "IE",
        code: "+353",
    },
    {
        id: 106,
        name: "Isle of Man",
        iso2: "IM",
        code: "+44",
    },
    {
        id: 107,
        name: "Israel",
        iso2: "IL",
        code: "+972",
    },
    {
        id: 108,
        name: "Italy",
        iso2: "IT",
        code: "+39",
    },
    {
        id: 109,
        name: "Jamaica",
        iso2: "JM",
        code: "+1 876",
    },
    {
        id: 110,
        name: "Japan",
        iso2: "JP",
        code: "+81",
    },
    {
        id: 111,
        name: "Jersey",
        iso2: "JE",
        code: "+44",
    },
    {
        id: 112,
        name: "Jordan",
        iso2: "JO",
        code: "+962",
    },
    {
        id: 113,
        name: "Kazakhstan",
        iso2: "KZ",
        code: "+7",
    },
    {
        id: 114,
        name: "Kenya",
        iso2: "KE",
        code: "+254",
    },
    {
        id: 115,
        name: "Kiribati",
        iso2: "KI",
        code: "+686",
    },
    {
        id: 116,
        name: "Korea, Democratic People's Republic of Korea",
        iso2: "KP",
        code: "+850",
    },
    {
        id: 117,
        name: "Korea, Republic of South Korea",
        iso2: "KR",
        code: "+82",
    },
    {
        id: 118,
        name: "Kosovo",
        iso2: "XK",
        code: "+383",
    },
    {
        id: 119,
        name: "Kuwait",
        iso2: "KW",
        code: "+965",
    },
    {
        id: 120,
        name: "Kyrgyzstan",
        iso2: "KG",
        code: "+996",
    },
    {
        id: 121,
        name: "Laos",
        iso2: "LA",
        code: "+856",
    },
    {
        id: 122,
        name: "Latvia",
        iso2: "LV",
        code: "+371",
    },
    {
        id: 123,
        name: "Lebanon",
        iso2: "LB",
        code: "+961",
    },
    {
        id: 124,
        name: "Lesotho",
        iso2: "LS",
        code: "+266",
    },
    {
        id: 125,
        name: "Liberia",
        iso2: "LR",
        code: "+231",
    },
    {
        id: 126,
        name: "Libyan Arab Jamahiriya",
        iso2: "LY",
        code: "+218",
    },
    {
        id: 127,
        name: "Liechtenstein",
        iso2: "LI",
        code: "+423",
    },
    {
        id: 128,
        name: "Lithuania",
        iso2: "LT",
        code: "+370",
    },
    {
        id: 129,
        name: "Luxembourg",
        iso2: "LU",
        code: "+352",
    },
    {
        id: 130,
        name: "Macao",
        iso2: "MO",
        code: "+853",
    },
    {
        id: 131,
        name: "Macedonia",
        iso2: "MK",
        code: "+389",
    },
    {
        id: 132,
        name: "Madagascar",
        iso2: "MG",
        code: "+261",
    },
    {
        id: 133,
        name: "Malawi",
        iso2: "MW",
        code: "+265",
    },
    {
        id: 134,
        name: "Malaysia",
        iso2: "MY",
        code: "+60",
    },
    {
        id: 135,
        name: "Maldives",
        iso2: "MV",
        code: "+960",
    },
    {
        id: 136,
        name: "Mali",
        iso2: "ML",
        code: "+223",
    },
    {
        id: 137,
        name: "Malta",
        iso2: "MT",
        code: "+356",
    },
    {
        id: 138,
        name: "Marshall Islands",
        iso2: "MH",
        code: "+692",
    },
    {
        id: 139,
        name: "Martinique",
        iso2: "MQ",
        code: "+596",
    },
    {
        id: 140,
        name: "Mauritania",
        iso2: "MR",
        code: "+222",
    },
    {
        id: 141,
        name: "Mauritius",
        iso2: "MU",
        code: "+230",
    },
    {
        id: 142,
        name: "Mayotte",
        iso2: "YT",
        code: "+262",
    },
    {
        id: 143,
        name: "Mexico",
        iso2: "MX",
        code: "+52",
    },
    {
        id: 144,
        name: "Micronesia, Federated States of Micronesia",
        iso2: "FM",
        code: "+691",
    },
    {
        id: 145,
        name: "Moldova",
        iso2: "MD",
        code: "+373",
    },
    {
        id: 146,
        name: "Monaco",
        iso2: "MC",
        code: "+377",
    },
    {
        id: 147,
        name: "Mongolia",
        iso2: "MN",
        code: "+976",
    },
    {
        id: 148,
        name: "Montenegro",
        iso2: "ME",
        code: "+382",
    },
    {
        id: 149,
        name: "Montserrat",
        iso2: "MS",
        code: "+1 664",
    },
    {
        id: 150,
        name: "Morocco",
        iso2: "MA",
        code: "+212",
    },
    {
        id: 151,
        name: "Mozambique",
        iso2: "MZ",
        code: "+258",
    },
    {
        id: 152,
        name: "Myanmar",
        iso2: "MM",
        code: "+95",
    },
    {
        id: 153,
        name: "Namibia",
        iso2: "NA",
        code: "+264",
    },
    {
        id: 154,
        name: "Nauru",
        iso2: "NR",
        code: "+674",
    },
    {
        id: 155,
        name: "Nepal",
        iso2: "NP",
        code: "+977",
    },
    {
        id: 156,
        name: "Netherlands",
        iso2: "NL",
        code: "+31",
    },
    {
        id: 157,
        name: "Netherlands Antilles",
        iso2: "AN",
        code: "+599",
    },
    {
        id: 158,
        name: "New Caledonia",
        iso2: "NC",
        code: "+687",
    },
    {
        id: 159,
        name: "New Zealand",
        iso2: "NZ",
        code: "+64",
    },
    {
        id: 160,
        name: "Nicaragua",
        iso2: "NI",
        code: "+505",
    },
    {
        id: 161,
        name: "Niger",
        iso2: "NE",
        code: "+227",
    },
    {
        id: 162,
        name: "Nigeria",
        iso2: "NG",
        code: "+234",
    },
    {
        id: 163,
        name: "Niue",
        iso2: "NU",
        code: "+683",
    },
    {
        id: 164,
        name: "Norfolk Island",
        iso2: "NF",
        code: "+672",
    },
    {
        id: 165,
        name: "Northern Mariana Islands",
        iso2: "MP",
        code: "+1 670",
    },
    {
        id: 166,
        name: "Norway",
        iso2: "NO",
        code: "+47",
    },
    {
        id: 167,
        name: "Oman",
        iso2: "OM",
        code: "+968",
    },
    {
        id: 168,
        name: "Pakistan",
        iso2: "PK",
        code: "+92",
    },
    {
        id: 169,
        name: "Palau",
        iso2: "PW",
        code: "+680",
    },
    {
        id: 170,
        name: "Palestinian Territory, Occupied",
        iso2: "PS",
        code: "+970",
    },
    {
        id: 171,
        name: "Panama",
        iso2: "PA",
        code: "+507",
    },
    {
        id: 172,
        name: "Papua New Guinea",
        iso2: "PG",
        code: "+675",
    },
    {
        id: 173,
        name: "Paraguay",
        iso2: "PY",
        code: "+595",
    },
    {
        id: 174,
        name: "Peru",
        iso2: "PE",
        code: "+51",
    },
    {
        id: 175,
        name: "Philippines",
        iso2: "PH",
        code: "+63",
    },
    {
        id: 176,
        name: "Pitcairn",
        iso2: "PN",
        code: "+",
    },
    {
        id: 177,
        name: "Poland",
        iso2: "PL",
        code: "+48",
    },
    {
        id: 178,
        name: "Portugal",
        iso2: "PT",
        code: "+351",
    },
    {
        id: 179,
        name: "Puerto Rico",
        iso2: "PR",
        code: "+1 787",
    },
    {
        id: 180,
        name: "Qatar",
        iso2: "QA",
        code: "+974",
    },
    {
        id: 181,
        name: "Romania",
        iso2: "RO",
        code: "+40",
    },
    {
        id: 182,
        name: "Russia",
        iso2: "RU",
        code: "+7",
    },
    {
        id: 183,
        name: "Rwanda",
        iso2: "RW",
        code: "+250",
    },
    {
        id: 184,
        name: "Reunion",
        iso2: "RE",
        code: "+262",
    },
    {
        id: 185,
        name: "Saint Barthelemy",
        iso2: "BL",
        code: "+590",
    },
    {
        id: 186,
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        iso2: "SH",
        code: "+290",
    },
    {
        id: 187,
        name: "Saint Kitts and Nevis",
        iso2: "KN",
        code: "+1 869",
    },
    {
        id: 188,
        name: "Saint Lucia",
        iso2: "LC",
        code: "+1 758",
    },
    {
        id: 189,
        name: "Saint Martin",
        iso2: "MF",
        code: "+590",
    },
    {
        id: 190,
        name: "Saint Pierre and Miquelon",
        iso2: "PM",
        code: "+508",
    },
    {
        id: 191,
        name: "Saint Vincent and the Grenadines",
        iso2: "VC",
        code: "+1 784",
    },
    {
        id: 192,
        name: "Samoa",
        iso2: "WS",
        code: "+685",
    },
    {
        id: 193,
        name: "San Marino",
        iso2: "SM",
        code: "+378",
    },
    {
        id: 194,
        name: "Sao Tome and Principe",
        iso2: "ST",
        code: "+239",
    },
    {
        id: 195,
        name: "Saudi Arabia",
        iso2: "SA",
        code: "+966",
    },
    {
        id: 196,
        name: "Senegal",
        iso2: "SN",
        code: "+221",
    },
    {
        id: 197,
        name: "Serbia",
        iso2: "RS",
        code: "+381",
    },
    {
        id: 198,
        name: "Seychelles",
        iso2: "SC",
        code: "+248",
    },
    {
        id: 199,
        name: "Sierra Leone",
        iso2: "SL",
        code: "+232",
    },
    {
        id: 200,
        name: "Singapore",
        iso2: "SG",
        code: "+65",
    },
    {
        id: 201,
        name: "Slovakia",
        iso2: "SK",
        code: "+421",
    },
    {
        id: 202,
        name: "Slovenia",
        iso2: "SI",
        code: "+386",
    },
    {
        id: 203,
        name: "Solomon Islands",
        iso2: "SB",
        code: "+677",
    },
    {
        id: 204,
        name: "Somalia",
        iso2: "SO",
        code: "+252",
    },
    {
        id: 205,
        name: "South Africa",
        iso2: "ZA",
        code: "+27",
    },
    {
        id: 206,
        name: "South Sudan",
        iso2: "SS",
        code: "+211",
    },
    {
        id: 207,
        name: "South Georgia and the South Sandwich Islands",
        iso2: "GS",
        code: "+",
    },
    {
        id: 208,
        name: "Spain",
        iso2: "ES",
        code: "+34",
    },
    {
        id: 209,
        name: "Sri Lanka",
        iso2: "LK",
        code: "+94",
    },
    {
        id: 210,
        name: "Sudan",
        iso2: "SD",
        code: "+249",
    },
    {
        id: 211,
        name: "Suriname",
        iso2: "SR",
        code: "+597",
    },
    {
        id: 212,
        name: "Svalbard and Jan Mayen",
        iso2: "SJ",
        code: "+47",
    },
    {
        id: 213,
        name: "Swaziland",
        iso2: "SZ",
        code: "+268",
    },
    {
        id: 214,
        name: "Sweden",
        iso2: "SE",
        code: "+46",
    },
    {
        id: 215,
        name: "Switzerland",
        iso2: "CH",
        code: "+41",
    },
    {
        id: 216,
        name: "Syrian Arab Republic",
        iso2: "SY",
        code: "+963",
    },
    {
        id: 217,
        name: "Taiwan",
        iso2: "TW",
        code: "+886",
    },
    {
        id: 218,
        name: "Tajikistan",
        iso2: "TJ",
        code: "+992",
    },
    {
        id: 219,
        name: "Tanzania, United Republic of Tanzania",
        iso2: "TZ",
        code: "+255",
    },
    {
        id: 220,
        name: "Thailand",
        iso2: "TH",
        code: "+66",
    },
    {
        id: 221,
        name: "Timor-Leste",
        iso2: "TL",
        code: "+670",
    },
    {
        id: 222,
        name: "Togo",
        iso2: "TG",
        code: "+228",
    },
    {
        id: 223,
        name: "Tokelau",
        iso2: "TK",
        code: "+690",
    },
    {
        id: 224,
        name: "Tonga",
        iso2: "TO",
        code: "+676",
    },
    {
        id: 225,
        name: "Trinidad and Tobago",
        iso2: "TT",
        code: "+1 868",
    },
    {
        id: 226,
        name: "Tunisia",
        iso2: "TN",
        code: "+216",
    },
    {
        id: 227,
        name: "Turkey",
        iso2: "TR",
        code: "+90",
    },
    {
        id: 228,
        name: "Turkmenistan",
        iso2: "TM",
        code: "+993",
    },
    {
        id: 229,
        name: "Turks and Caicos Islands",
        iso2: "TC",
        code: "+1 649",
    },
    {
        id: 230,
        name: "Tuvalu",
        iso2: "TV",
        code: "+688",
    },
    {
        id: 231,
        name: "Uganda",
        iso2: "UG",
        code: "+256",
    },
    {
        id: 232,
        name: "Ukraine",
        iso2: "UA",
        code: "+380",
    },
    {
        id: 233,
        name: "United Arab Emirates",
        iso2: "AE",
        code: "+971",
    },
    {
        id: 234,
        name: "United Kingdom",
        iso2: "UK",
        code: "+",
    },
    {
        id: 235,
        name: "United States",
        iso2: "US",
        code: "+1",
    },
    {
        id: 236,
        name: "Uruguay",
        iso2: "UY",
        code: "+598",
    },
    {
        id: 237,
        name: "Uzbekistan",
        iso2: "UZ",
        code: "+998",
    },
    {
        id: 238,
        name: "Vanuatu",
        iso2: "VU",
        code: "+678",
    },
    {
        id: 239,
        name: "Venezuela, Bolivarian Republic of Venezuela",
        iso2: "VE",
        code: "+58",
    },
    {
        id: 240,
        name: "Vietnam",
        iso2: "VN",
        code: "+84",
    },
    {
        id: 241,
        name: "Virgin Islands, British",
        iso2: "VG",
        code: "+1284",
    },
    {
        id: 242,
        name: "Virgin Islands, U.S.",
        iso2: "VI",
        code: "+1340",
    },
    {
        id: 243,
        name: "Wallis and Futuna",
        iso2: "WF",
        code: "+681",
    },
    {
        id: 244,
        name: "Yemen",
        iso2: "YE",
        code: "+967",
    },
    {
        id: 245,
        name: "Zambia",
        iso2: "ZM",
        code: "+260",
    },
    {
        id: 246,
        name: "Zimbabwe",
        iso2: "ZW",
        code: "+263",
    },
    {
        id: 247,
        name: "Ascension Island",
        iso2: "AC",
        code: "+247",
    },
    {
        id: 248,
        name: "Bolivia, Plurinational State Of",
        iso2: "BO",
        code: "+591",
    },
    {
        id: 249,
        name: "Bonaire, Saint Eustatius And Saba",
        iso2: "BQ",
        code: "+599",
    },
    {
        id: 250,
        name: "Bosnia & Herzegovina",
        iso2: "BA",
        code: "+387",
    },
    {
        id: 251,
        name: "Canary Islands",
        iso2: "IC",
        code: "+",
    },
    {
        id: 252,
        name: "Ceuta, Mulilla",
        iso2: "EA",
        code: "+",
    },
    {
        id: 253,
        name: "Clipperton Island",
        iso2: "CP",
        code: "+",
    },
    {
        id: 254,
        name: "Curacao",
        iso2: "CW",
        code: "+599",
    },
    {
        id: 255,
        name: "Democratic Republic Of Congo",
        iso2: "CD",
        code: "+243",
    },
    {
        id: 256,
        name: "Diego Garcia",
        iso2: "DG",
        code: "+",
    },
    {
        id: 257,
        name: "East Timor",
        iso2: "TL",
        code: "+670",
    },
    {
        id: 258,
        name: "European Union",
        iso2: "EU",
        code: "+388",
    },
    {
        id: 259,
        name: "Falkland Islands",
        iso2: "FK",
        code: "+500",
    },
    {
        id: 260,
        name: "France, Metropolitan",
        iso2: "FX",
        code: "+241",
    },
    {
        id: 261,
        name: "Iran, Islamic Republic Of",
        iso2: "IR",
        code: "+98",
    },
    {
        id: 262,
        name: "Korea, Democratic People's Republic Of",
        iso2: "KP",
        code: "+850",
    },
    {
        id: 263,
        name: "Korea, Republic Of",
        iso2: "KR",
        code: "+82",
    },
    {
        id: 264,
        name: "Lao People's Democratic Republic",
        iso2: "LA",
        code: "+856",
    },
    {
        id: 265,
        name: "Libya",
        iso2: "LY",
        code: "+218",
    },
    {
        id: 266,
        name: "Macedonia, The Former Yugoslav Republic Of",
        iso2: "MK",
        code: "+389",
    },
    {
        id: 267,
        name: "Micronesia, Federated States Of",
        iso2: "FM",
        code: "+691",
    },
    {
        id: 268,
        name: "Republic Of Congo",
        iso2: "CG",
        code: "+242",
    },
    {
        id: 269,
        name: "Russian Federation",
        iso2: "RU",
        code: "+7",
    },
    {
        id: 270,
        name: "Sint Maarten",
        iso2: "SX",
        code: "+1 721",
    },
    {
        id: 271,
        name: "Taiwan, Province Of China",
        iso2: "TW",
        code: "+886",
    },
    {
        id: 272,
        name: "Tanzania, United Republic Of",
        iso2: "TZ",
        code: "+255",
    },
    {
        id: 273,
        name: "Tristan de Cunha",
        iso2: "TA",
        code: "+290",
    },
    {
        id: 274,
        name: "USSR",
        iso2: "SU",
        code: "+",
    },
    {
        id: 275,
        name: "United States Minor Outlying Islands",
        iso2: "UM",
        code: "+",
    },
    {
        id: 276,
        name: "Vatican City State",
        iso2: "VA",
        code: "+379",
    },
    {
        id: 277,
        name: "Venezuela, Bolivarian Republic Of",
        iso2: "VE",
        code: "+58",
    },
    {
        id: 278,
        name: "Viet Nam",
        iso2: "VN",
        code: "+84",
    },
    {
        id: 279,
        name: "Virgin Islands (British)",
        iso2: "VG",
        code: "+1 284",
    },
    {
        id: 280,
        name: "Virgin Islands (US)",
        iso2: "VI",
        code: "+1 340",
    },
    {
        id: 281,
        name: "Western Sahara",
        iso2: "EH",
        code: "+212",
    },
];
