import React from 'react';
import BlogIntor from '../component/blog/blog-intro';
import BlogBody from '../component/blog/blog-body';


const BlogPage = () => {

    React.useEffect(() => {
        document.title = "Blog | Reverse , Renewal Mortgage Toronto "
    }, []);


    return (
        <>
            <BlogIntor />
            <BlogBody />
        </>
    );
}

export default BlogPage;