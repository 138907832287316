import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ConnectMortgageExperts() {
  return (
    <div className="mortgage-experts-wrapper">
        <Container>
            <Row>
                <Col md={10} className="mortgage-experts-col m-auto">
                    <div className='mortgage-experts-text'>
                          <p>We’ll help you with every requirement and look through every detail to ensure you stand to benefit the most from the entire mortgage process. Whether you are self-employed or do not qualify for a traditional mortgage, speak with our mortgage agents. We’ll help you understand your options and get you closer to owning your dream home.</p>
                          <h3>Are you finding it difficult to secure a mortgage because you are self-employed? Connect with us to find the right financing options for your home.</h3>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ConnectMortgageExperts