import React from "react";
import { Routes, Route } from "react-router-dom";

import FrontendLayot from "./layout/frontendLayot";
import AboutPage from "./pages/about-mortgage-agents-toronto";
import MortgageRefinance from "./pages/toronto-mortgage-refinance-services";
import HomePurchase from "./pages/toronto-home-purchase-mortgage-services";
import SelfEmployedMortgage from "./pages/toronto-self-employed-mortgage-services";
import HomeEquityLine from "./pages/toronto-home-equity-line-of-credit-services";
import InvestmentProperty from "./pages/toronto-investment-property-mortgage-services";
import ReverseMortgage from "./pages/toronto-reverse-mortgage-services";
import ContactPage from "./pages/contact-mortgage-specialists-agents-toronto";
import HomePage from "./pages/home";
import BlogPage from "./pages/blog";
import NotFound from "./component/NotFound";

function App() {
    return (
        <FrontendLayot>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about-mortgage-agents-toronto" element={<AboutPage />} />
                <Route path="/toronto-mortgage-refinance-services" element={<MortgageRefinance />} />
                <Route path="/toronto-home-purchase-mortgage-services" element={<HomePurchase />} />
                <Route path="/toronto-self-employed-mortgage-services" element={<SelfEmployedMortgage />} />
                <Route path="/toronto-home-equity-line-of-credit-services" element={<HomeEquityLine />} />
                <Route path="/toronto-investment-property-mortgage-services" element={<InvestmentProperty />} />
                <Route path="/toronto-reverse-mortgage-services" element={<ReverseMortgage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/contact-mortgage-specialists-agents-toronto" element={<ContactPage />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </FrontendLayot>
    );
}

export default App;
