import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ConnectMortgageExperts() {
  return (
    <div className="mortgage-experts-wrapper">
        <Container>
            <Row>
                <Col md={10} className="mortgage-experts-col m-auto">
                    <div className='mortgage-experts-text'>
                        <h2>Connect With Our Mortgage Experts</h2>
                        <div className="small-image"><img className="img-fluid" src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <p>At Sunlite Mortgage Alliance, we will assess your current financial situation and help you understand whether refinancing is the right choice. We’ll break down the costs and penalties of refinancing and weigh them against the benefits. Our mortgage experts will evaluate every detail to ensure you benefit from the process and save more in the long run. We will also help you understand how to borrow funds against your home equity. Trust our experienced mortgage agents to find the most competitive rates and guide you through the entire process.</p>
                        <h3>Are you considering refinancing your mortgage? Talk to us before you do. We’ll advise you on the right way forward.</h3>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ConnectMortgageExperts