import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function BlogIntro() {
  return (
    <div className='blog-body-wrapper'>
      <Container>
        <Row>
          <Col md={9}>
            <div className="blog-left-side">
              <div className="blog-right-side-title">
                <h2>ANNOUNCING THE NEW WEBSITE</h2>
              </div>
              <div className="blog-left-side-other">
                <ul className="blog-left-side-ul">
                  <li> Categories: </li>
                  <li><a href="#">Bad Credit Mortgage</a></li>
                  <li><a href="#">Bruised Credit</a></li>
                  <li><a href="#">Commercial Mortgage</a></li>
                  <li><a href="#">Construction Mortgage</a></li>
                  <li><a href="#">Debt Consolidation Mortgage</a></li>
                  <li><a href="#">First-Time Home Buyers</a></li>
                  <li><a href="#">Home Equity Line Of Credit</a></li>
                  <li><a href="#">Home Equity Loan</a></li>
                  <li><a href="#">Home Improvement Mortgage</a></li>
                  <li><a href="#">Home Purchase Mortgage</a></li>
                  <li><a href="#">International Home Purchase</a></li>
                  <li><a href="#">Investment Property Mortgage</a></li>
                  <li><a href="#">Low Credit Mortgage</a></li>
                  <li><a href="#">Mortgage Advisors</a></li>
                  <li><a href="#">Mortgage Agents</a></li>
                  <li><a href="#">Mortgage Brokers</a></li>
                  <li><a href="#">Mortgage Experts</a></li>
                  <li><a href="#">Mortgage Pre Approval</a></li>
                  <li><a href="#">Mortgage Refinance</a></li>
                  <li><a href="#">Mortgage Renewal</a></li>
                  <li><a href="#">Bruised Credit</a></li>
                  <li><a href="#">Commercial Mortgage</a></li>
                  <li><a href="#">Construction Mortgage</a></li>
                  <li><a href="#">Debt Consolidation Mortgage</a></li>
                  <li><a href="#">First-Time Home Buyers</a></li>
                  <li><a href="#">Home Equity Line Of Credit</a></li>
                  <li><a href="#">Home Equity Loan</a></li>
                  <li><a href="#">Home Improvement Mortgage</a></li>
                  <li><a href="#">Home Purchase Mortgage</a></li>
                </ul>
              </div>
              <div className='blog-image'>
                <img className='img-fluid' src="../../assets/blog-page-main.png" alt="Sunlite Mortgage" />
              </div>
              <Link to="#" className="btn btn-home-link">CLICK HERE TO READ THE FULL ARTICLE</Link>
            </div>
          </Col>
          <Col md={3}>
            <div className="blog-right-side">
              <div className="blog-right-side-title">
                <h2>TAGS</h2>
              </div>
              <ul className="blog-ul">
                <li><a href="#">Bad Credit Mortgage</a></li>
                <li><a href="#">Bruised Credit</a></li>
                <li><a href="#">Commercial Mortgage</a></li>
                <li><a href="#">Construction Mortgage</a></li>
                <li><a href="#">Debt Consolidation Mortgage</a></li>
                <li><a href="#">First-Time Home Buyers</a></li>
                <li><a href="#">Home Equity Line Of Credit</a></li>
                <li><a href="#">Home Equity Loan</a></li>
                <li><a href="#">Home Improvement Mortgage</a></li>
                <li><a href="#">Home Purchase Mortgage</a></li>
                <li><a href="#">International Home Purchase</a></li>
                <li><a href="#">Investment Property Mortgage</a></li>
                <li><a href="#">Low Credit Mortgage</a></li>
                <li><a href="#">Mortgage Advisors</a></li>
                <li><a href="#">Mortgage Agents</a></li>
                <li><a href="#">Mortgage Brokers</a></li>
                <li><a href="#">Mortgage Experts</a></li>
                <li><a href="#">Mortgage Pre Approval</a></li>
                <li><a href="#">Mortgage Refinance</a></li>
                <li><a href="#">Mortgage Renewal</a></li>
                <li><a href="#">Mortgage Services</a></li>
                <li><a href="#">Mortgage Specialists</a></li>
                <li><a href="#">New to Canada Mortgage</a></li>
                <li><a href="#">No Commitment Mortgage</a></li>
                <li><a href="#">Non Resident Mortgage</a></li>
                <li><a href="#">Private Mortgages</a></li>
                <li><a href="#">Purchase Plus Improvements Mortgage</a></li>
                <li><a href="#">Reverse Mortgage</a></li>
                <li><a href="#">Second Mortgage</a></li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BlogIntro;