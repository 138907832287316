import React from 'react';
import ReverseMortgageIntor from '../component/reverse-mortgage/home-purchase-intro';
import ReverseMortgageMiddleText from '../component/reverse-mortgage/mortgage-refinancing';
import ConnectMortgageExperts from '../component/reverse-mortgage/connect-mortgage-experts';
import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
import ContactBtn from '../component/contact-btn';
// import Footer from '../component/footer';
// import Header from '../component/header';


const ReverseMortgage = () => {

    React.useEffect(()=>{
        document.title = "Reverse Mortgage Services Toronto"
    },[]);

    return (
        <>
            <ReverseMortgageIntor />
            <ReverseMortgageMiddleText />
            <ConnectMortgageExperts />
            <SliderMortgageSolutions/>
            <ContactBtn/>
        </>
    );
}

export default ReverseMortgage;