import React from 'react';
import {Link} from 'react-router-dom';
import { Container} from 'react-bootstrap';

function HomeSlider() {
  return (
    <Container fluid className='home-slider-image-wrapper'>
        <Link to="/contact-mortgage-specialists-agents-toronto">
            <div className='home-slider-image'>
                <div className='home-slider-image-text-wrapper'>
                    <div className='home-slider-image-text'>
                        <h1>Providing Customized Mortgage Solutions To Canadians Since 2003</h1>
                    </div>
                    <div className='slider-btn-wrapper'>
                        <button className="btn">GET IN TOUCH</button>
                    </div>
                </div>
            </div>
        </Link>
    </Container>
  )
}

export default HomeSlider