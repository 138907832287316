import React from 'react';
import {Link} from 'react-router-dom';
import { Container } from 'react-bootstrap';

function ContactBtn() {
  return (
    <div className='contact-btn-section'>
        <Container>
            <Link to="/contact-mortgage-specialists-agents-toronto" className="btn btn-home-link">Contact Sunlite Mortgage Brokers in Toronto, ON</Link>
        </Container>
    </div>
  )
}

export default ContactBtn