import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ConnectMortgageExperts() {
    return (
        <div className="mortgage-experts-wrapper">
            <Container>
                <Row>
                    <Col md={10} className="mortgage-experts-col m-auto">
                        <div className='mortgage-experts-text'>
                            <p>At Sunlite Mortgage Alliance, we make the process easy and convenient for you. Finding the best mortgage rates that work for your situation is what we do. If you are a first-time home buyer, we’ll help you take advantage of the grants and programs available by the federal government. Our experienced mortgage advisors will help you through the entire process, from pre-approval to closing. We help you understand the buying process and how to qualify for the right loan. Based on your income, savings, debt, and credit score, we help you know how much you are eligible for. If you do not qualify for a traditional mortgage, speak to us. We’ll find alternative financing options that work for you.</p>
                            <h3>Do you have your heart set on buying a home but don’t know where to start? Connect with us to explore your options and find the best mortgage rates.</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ConnectMortgageExperts