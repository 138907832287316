import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function HomePurchaseIntro() {
  return (
    <div className='home-purchase-page-section'>
        <Container>
            <div className='about-page-text'>
                <h1>Home Purchase Mortgage Services</h1>
                <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                <h3>Mortgage Advisors /Specialists in Toronto, Ontario</h3>
            </div>
              <Row className='home-purchase-page-row'>
                <Col md={6}>
                      <div className='home-purchase-page-image'>
                          <img className='img-fluid' src="../../assets/home-purchase-page-intor.jpg" alt=" Mortgage Solutions Toronto" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='home-purchase-page-text'>
                          <h2>Home Purchase</h2>
                          <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                          <p>Purchasing a home is both exciting and nerve-racking. At Sunlite Mortgage Alliance, we want you to feel confident with your decision, so we do our best to assist you throughout the process with professional advice and guidance at every step. We do the research for you and present you with the most competitive rates and options tailored to your requirements. Finding the right mortgage lender, comparing rates, scrutinizing every term, and other details of the mortgage process can get overwhelming. Working with us, you will find that you can breathe a sigh of relief. You will be able to understand your options clearly and find the right mortgage for your new home. We will assist you through the entire process and help you find the right financing for your situation.</p>
                    </div>           
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default HomePurchaseIntro