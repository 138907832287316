import React from 'react';
import HomeSlider from '../component/home/home-slider';
import HomeIntro from '../component/home/intro';
import HomeService from '../component/home/home-service';
import HomeContact from '../component/home/home-contact';
import HomeBlog from '../component/home/home-blog';

import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
// import Footer from '../component/footer';
// import Header from '../component/header';


const HomePage = () => {

    React.useEffect(() => {
        document.title = "Mortgage Brokers, Agents Toronto, Ontario | Sunlite Mortgage "
    }, []);

    return (
        <>
            {/* <Header/> */}
            <HomeSlider/>
            <HomeIntro/>
            <HomeService/>
            <HomeContact/>
            <HomeBlog/>
            <SliderMortgageSolutions/>
            {/* <Footer/> */}
        </>
    );
}

export default HomePage;