import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

function HomePurchaseMiddleText() {
  return (
      <div className='mortgage-refinancing-wrapper'>
          <Container>
              <Row>
                  <Col md={11} className="m-auto">
                      <div className='mortgage-refinancing-right home-purchase-middle-text'>
                          <p>Buying a home is a long-term investment and one of the most significant purchases you will ever make. This is why it is best to consult an experienced mortgage specialist to guide you through the process. A mortgage agent will help you -</p>
                          <ul>
                              <li> <FaLongArrowAltRight /> Find the most competitive rates from multiple lenders </li>
                              <li> <FaLongArrowAltRight /> Match you with the right mortgage product for your needs </li>
                              <li> <FaLongArrowAltRight /> Take advantage of rebates and other government incentives for first-time home buyers </li>
                              <li> <FaLongArrowAltRight /> Assess your finances to find out how much you can afford </li>
                              <li> <FaLongArrowAltRight /> Guide your through the entire process, from Pre-approvals and approvals to closing </li>
                          </ul>
                      </div>
                  </Col>
              </Row>
          </Container>
      </div>
  )
}

export default HomePurchaseMiddleText