import React from 'react';
import SelfEmployedIntor from '../component/self-employed-mortgage/home-purchase-intro';
import SelfEmployedMiddleText from '../component/self-employed-mortgage/mortgage-refinancing';
import ConnectMortgageExperts from '../component/self-employed-mortgage/connect-mortgage-experts';
import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
import ContactBtn from '../component/contact-btn';
// import Footer from '../component/footer';
// import Header from '../component/header';


const SelfEmployedMortgage = () => {

    React.useEffect(()=>{
        document.title = "Self Employed Mortgage Services"
    },[]);

    return (
        <>
            <SelfEmployedIntor />
            <SelfEmployedMiddleText />
            <ConnectMortgageExperts />
            <SliderMortgageSolutions/>
            <ContactBtn/>
        </>
    );
}

export default SelfEmployedMortgage;