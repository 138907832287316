import React from "react";
import {Link} from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { BsFillTelephoneFill, } from 'react-icons/bs';

function Headerintor() {
  return (
    <Container className="top-header-wrapper">
        <Row>
            <Col md={3} className="logo-left-wrapper">
                <ul className="top-contact-icon-wrapper">
                    <a href="tel:(647) 361-8443"><BsFillTelephoneFill/> (647) 361-8443 </a>
                    <a href="tel:(877) 385-6267"><BsFillTelephoneFill/> (877) 385-6267 </a>
                </ul>
            </Col>
            <Col md={6} className="logo-wrapper">
                <Link to="/" className="">
                    <img className="img-fluid" src="../../assets/logo.png" alt="Sunlitemortgagealliance" />
                </Link>
            </Col>
            <Col md={3} className="logo-right-wrapper">
                <Link to="contact-mortgage-specialists-agents-toronto" className="btn">E-MAIL NOW</Link>
            </Col>
        </Row>
    </Container>
  )
}

export default Headerintor