import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function HomePurchaseIntro() {
  return (
    <div className='home-purchase-page-section'>
        <Container>
            <div className='about-page-text'>
                <h1>Self-Employed Mortgage Services</h1>
                <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                <h3>Mortgage Advisors /Specialists in Toronto, ON</h3>
            </div>
              <Row className='home-purchase-page-row'>
                <Col md={6}>
                      <div className='home-purchase-page-image'>
                        <img className='img-fluid' src="../../assets/self-mortgage-intro.jpg" alt="Self-Employed Mortgage Services" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='home-purchase-page-text self-mortgage-page-text'>
                      <h2>Self-Employed Mortgage</h2>
                          <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                          <p>If you are self-employed, you may find it challenging to get a conventional mortgage to purchase a home. Lenders usually require extensive paperwork, proof of income, credit reports, and other documentation to secure their investment.</p>
                          <p>As a self-employed individual, you declare your earnings differently, this may not always fit standard lender requirements. This does not mean that you should give up on your dream home. Yes, the process is overwhelming, but we’re here to help you find the right financing for your purchase.</p>
                    </div>           
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default HomePurchaseIntro