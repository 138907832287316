import React from "react";

const ConactMap = () => {
    return (
        <div className="contact-right-map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.1184770829896!2d-79.3316050846309!3d43.72886557911871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d0e511da71df%3A0x5316e3c150b11752!2sSunlite%20Mortgage%20Alliance!5e0!3m2!1sen!2sbd!4v1665821983394!5m2!1sen!2sbd"
                width="100%"
                height="548"
                style={{ border: "0" }}
                title="Map"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
        </div>
    );
};

export default ConactMap;
