import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function AboutIntro() {
  return (
    <div className='about-page-section'>
        <Container>
            <div className='about-page-text'>
                <h1>About Sunlite Mortgage</h1>
                <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                <h3>Licensed Mortgage Agents in Toronto, Ontario</h3>
            </div>
            <Row className='about-page-row'>
                <Col md={6}>
                    <div className='about-page-row-image'>
                        <img className='img-fluid' src="../../assets/about.png" alt=" Mortgage Solutions Toronto" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='about-page-row-text'>
                      <p>Sunlite Mortgage Alliance has been providing customized mortgage solutions to Canadians since 2003 and has seen many changes in the mortgage industry. We work with over 100 mortgage lenders including, banks, credit unions, trust companies, and private lenders to help Canadians whether they are first-time home buyers purchasing their first home, new to Canada, looking to transfer a mortgage, or refinancing an existing mortgage to consolidate debt, our team of mortgage agents can help you find the best mortgage solution.</p>
                    </div>           
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default AboutIntro