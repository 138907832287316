import React, { useLayoutEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { AiFillCaretUp } from "react-icons/ai";

function Header() {
    const stickyHeader = useRef();
    useLayoutEffect(() => {
        const mainHeader = document.getElementById("main_header");
        const backToTopElement = document.getElementById("back_to_top");
        let fixedTop = stickyHeader.current.offsetTop;
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                mainHeader.classList.add("fixedTop");
                backToTopElement.style.opacity = 1;
            } else {
                mainHeader.classList.remove("fixedTop");
                backToTopElement.style.opacity = 0;
            }
        };
        window.addEventListener("scroll", fixedHeader);

        const backToTop = () => {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        };
        backToTopElement.addEventListener("click", backToTop);
    }, []);

    const menus = [
        { url: "/", name: "Home" },
        { url: "about-mortgage-agents-toronto", name: "About" },
        { url: "toronto-mortgage-refinance-services", name: "Mortgage Refinance" },
        { url: "toronto-home-purchase-mortgage-services", name: "Home Purchase" },
        {
            url: "toronto-self-employed-mortgage-services",
            name: "Self Employed Mortgage",
        },
        { url: "toronto-home-equity-line-of-credit-services", name: "Home Equity Line Of Credit" },
        { url: "toronto-investment-property-mortgage-services", name: "Investment Property Mortgage" },
        { url: "toronto-reverse-mortgage-services", name: "Reverse Mortgage" },
        { url: "blog", name: "Blog" },
        { url: "contact-mortgage-specialists-agents-toronto", name: "Contact Us" },
    ];

    return (
        <>
            <Navbar bg="light" expand="lg" id="main_header" className="sticky-top" ref={stickyHeader}>
                <Container>
                    <Navbar.Brand as={NavLink} to="/">
                        <img className="img-fluid" src="../../assets/logo.png" alt="Sunlitemortgagealliance" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {menus.map((menu, index) => (
                                <Nav.Link end as={NavLink} key={index} to={menu.url}>
                                    {menu.name}
                                </Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Button variant="dark" id="back_to_top" className="back-to-top">
                <AiFillCaretUp />
            </Button>
        </>
    );
}

export default Header;
