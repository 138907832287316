import React from 'react'
import Footer from '../component/footer'
import Header from '../component/header'
import Headerintor from '../component/header-intro'

function FrontendLayot({children}) {
  
  return (
    <>  
        <Headerintor/>
        <Header/>
        {children}
        <Footer/>
    </>
  )
}

export default FrontendLayot