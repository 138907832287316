import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ConnectMortgageExperts() {
  return (
    <div className="mortgage-experts-wrapper">
        <Container>
            <Row>
                <Col md={10} className="mortgage-experts-col m-auto">
                    <div className='mortgage-experts-text'>
                      <p>While there can be many benefits from reverse mortgages, there are drawbacks too. This includes a higher interest rate and a decrease in equity. Come talk to our mortgage experts at Sunlite Mortgage Alliance. We will help you understand how a reverse mortgage decreases your home equity and the restrictions involved. We will weigh every pro and con so you can make a confident decision. It is essential to be aware of any fees or hidden terms and know what can cause you to default. At Sunlite Mortgage Alliance, we ask the questions for you so that you stand to benefit the most from your mortgage. We will evaluate every detail and advise you whether a reverse mortgage is the right option for you. We know it can be challenging to navigate this alone; that’s why we are here to help you.</p>
                      <h3>Are you considering a reverse mortgage? We’re here to help. Connect with us to understand your options and take the right step towards a financially secure future.</h3>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ConnectMortgageExperts