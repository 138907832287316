import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

function MortgageRefinancing() {
  return (
    <div className='mortgage-refinancing-wrapper'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='mortgage-refinancing-left'>
                        <h2>What Is Mortgage Refinancing?</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <p>Refinancing is replacing your existing mortgage with a new loan. The new loan will have different interest rates and payment terms and will pay off your current mortgage balance. When done right, you will end up with additional funds at your disposal for other financial goals. Also, if your finances and credit score have improved since purchasing your home - you will have access to better mortgage options and lower interest rates.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='mortgage-refinancing-right'>
                        <h2>Benefits Of Refinancing Your Mortgage</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <ul>
                            <li> <FaLongArrowAltRight/> Take advantage of lower interest rates </li>
                            <li> <FaLongArrowAltRight/> Access home equity </li>
                            <li> <FaLongArrowAltRight/> Consolidate debt </li>
                            <li> <FaLongArrowAltRight/> Increase cash flow </li>
                            <li> <FaLongArrowAltRight/> Cover larger expenses like home renovations </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MortgageRefinancing