import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function RefinanceIntro() {
  return (
    <div className='refinance-intro-wrapper'>
        <Container>
            <Row>
                <Col md={6}>
                </Col>
                <Col md={6}>
                    <div className='refinance-intro-top'>
                        <h1>Mortgage Refinance Services</h1>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                        <h2>Mortgage Brokers / Agents in Toronto, Ontario</h2>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default RefinanceIntro