import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLongArrowAltRight } from 'react-icons/fa';

function MortgageRefinancing() {
  return (
    <div className='mortgage-refinancing-wrapper'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='mortgage-refinancing-left'>
                        <h2>What Is An Investment Property Mortgage?</h2>
                        <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                          <p>An investment property generally means real estate that is purchased with the intent to generate income. Most investment property mortgages require a 20% down payment. This helps you qualify for a longer amortization period of around 30 years. An investment property helps provide -</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='mortgage-refinancing-right'>
                      <ul>
                        <li> <FaLongArrowAltRight /> An additional stream of income </li>
                        <li> <FaLongArrowAltRight /> Allows you to expand your assets </li>
                        <li> <FaLongArrowAltRight /> Money from rentals can help pay off your mortgage or clear other debts </li>
                        <li> <FaLongArrowAltRight /> Build equity over time </li>
                      </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MortgageRefinancing