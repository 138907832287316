import React from 'react';
import HomePurchaseIntor from '../component/home-purchase/home-purchase-intro';
import HomePurchaseMiddleText from '../component/home-purchase/home-purchase-middle-text';
import ConnectMortgageExperts from '../component/home-purchase/home-purchase-text';
import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
import ContactBtn from '../component/contact-btn';
// import Footer from '../component/footer';
// import Header from '../component/header';


const HomePurchase = () => {

    React.useEffect(()=>{
        document.title = "Home Purchase Mortgage Toronto"
    },[]);

    return (
        <>
            <HomePurchaseIntor />
            <HomePurchaseMiddleText />
            <ConnectMortgageExperts />
            <SliderMortgageSolutions/>
            <ContactBtn/>
        </>
    );
}

export default HomePurchase;