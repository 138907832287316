import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function HomePurchaseIntro() {
  return (
    <div className='home-purchase-page-section'>
        <Container>
            <div className='about-page-text'>
          <h1>Investment Property Mortgage Services</h1>
              <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Home Equity Line of Credit Services" /></div>
              <h3>Mortgage Advisors / Brokers in Toronto, Ontario</h3>
            </div>
              <Row className='home-purchase-page-row'>
                <Col md={6}>
                      <div className='home-purchase-page-image'>
              <img className='img-fluid' src="../../assets/investment-property-intro.jpg" alt="Investment Property Mortgage Services" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='home-purchase-page-text self-mortgage-page-text'>
                      <h2>Investment Property Mortgage</h2>
                          <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                          <p>Investing in real estate provides you with a reliable source of income from rentals or through capital appreciation. At Sunlite Mortgage Alliance, we have helped many investors successfully purchase investment properties with the best mortgage rates and financing plans.</p>
                          <p>If you are looking for an investment property mortgage, we’ll help you find the right lender and the most competitive rates. We do the negotiating for you so that you end up saving more - both time and money.</p>
                    </div>           
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default HomePurchaseIntro