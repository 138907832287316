import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ConnectMortgageExperts() {
  return (
    <div className="mortgage-experts-wrapper">
        <Container>
            <Row>
                <Col md={10} className="mortgage-experts-col m-auto">
                    <div className='mortgage-experts-text'>
                        <p>At Sunlite Mortgage Alliance, we help you access the right type of financing to improve your life and secure your future. We have helped numerous homeowners understand how home equity works and how they can use it to their advantage. As experienced mortgage advisors, we guide you through the entire process, explain every requisite, assist you with the paperwork, and ensure you benefit the most. You get financial clarity, professional advice, and a smooth and easy approval process.</p>
                        <h3>Are you looking to tap into your home equity? We’ll help you understand your options and guide you through the process.</h3>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ConnectMortgageExperts