import React from 'react';
import {Link} from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

function HomeService() {
  return (
    <section className='home-service-section'>
        <Container>
            <div className='home-service-text'>
                <h2>Services</h2>
                <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                <h3>What We Provide</h3>
            </div>
        </Container>
    <div className='home-service-bg-image'>
        <Container>
            <Row className='home-service-row'>
                <Col md={9} className='m-auto'>
                    <Row>  
                        <Col md={4}>
                    <div className='home-service-single'>
                        <Link to="/toronto-mortgage-refinance-services" className="btn-service-link">
                            <img className='img-fluid' src="../../assets/mortgage-refinance.png" alt="Mortgage Refinance" />
                            <div className="description">
                                <h2 className="section-subtitle">Mortgage Refinance</h2>
                                <div className="ser-line"></div>
                                <button className="btn btn-service-btn"> Learn More </button>
                            </div>
                        </Link>  
                    </div>
                </Col>
                <Col md={4}>
                    <div className='home-service-single home-purchase'>
                        <Link to="/toronto-home-purchase-mortgage-services" className="btn-service-link">
                            <img className='img-fluid' src="../../assets/home-purchase.png" alt="Home Purchase" />
                            <div className="description">
                                <h2 className="section-subtitle">Home Purchase</h2>
                                <div className="ser-line"></div>
                                <button className="btn btn-service-btn"> Learn More </button>
                            </div>
                        </Link>  
                    </div>
                </Col>
                <Col md={4}>
                    <div className='home-service-single self-employed'>
                        <Link to="/toronto-self-employed-mortgage-services" className="btn-service-link">
                            <img className='img-fluid' src="../../assets/self-employed.png" alt="Self Employed Mortgage" />
                            <div className="description">
                                <h2 className="section-subtitle">Self Employed Mortgage</h2>
                                <div className="ser-line"></div>
                                <button className="btn btn-service-btn"> Learn More </button>
                            </div>
                        </Link>  
                    </div>
                </Col>
                <Col md={4}>
                    <div className='home-service-single home-equity'>
                        <Link to="/toronto-home-equity-line-of-credit-services" className="btn-service-link">
                            <img className='img-fluid' src="../../assets/home-equity-line-of-credit.png" alt="Home Equity Line Of Credit" />
                            <div className="description">
                                <h2 className="section-subtitle">Home Equity Line Of Credit</h2>
                                <div className="ser-line"></div>
                                <button className="btn btn-service-btn"> Learn More </button>
                            </div>
                        </Link>  
                    </div>
                </Col>
                <Col md={4}>
                    <div className='home-service-single investment-property'>
                        <Link to="/toronto-investment-property-mortgage-services" className="btn-service-link">
                            <img className='img-fluid' src="../../assets/investment-property.png" alt="Investment Property Mortgage" />
                            <div className="description">
                                <h2 className="section-subtitle">Investment Property Mortgage</h2>
                                <div className="ser-line"></div>
                                <button className="btn btn-service-btn"> Learn More </button>
                            </div>
                        </Link>  
                    </div>
                </Col>
                <Col md={4}>
                    <div className='home-service-single reverse-mortgage'>
                        <Link to="/toronto-reverse-mortgage-services" className="btn-service-link">
                            <img className='img-fluid' src="../../assets/reverse-mortgage.png" alt="Reverse Mortgage" />
                            <div className="description">
                                <h2 className="section-subtitle">Reverse Mortgage</h2>
                                <div className="ser-line"></div>
                                <button className="btn btn-service-btn"> Learn More </button>
                            </div>
                        </Link>  
                    </div>
                </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
    </section>
  )
}

export default HomeService