import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function HomePurchaseIntro() {
  return (
    <div className='home-purchase-page-section'>
        <Container>
            <div className='about-page-text'>
              <h1>Home Equity Line of Credit Services</h1>
              <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Home Equity Line of Credit Services" /></div>
              <h3>Mortgage Advisors / Agents in Toronto, Ontario</h3>
            </div>
              <Row className='home-purchase-page-row'>
                <Col md={6}>
                      <div className='home-purchase-page-image'>
                      <img className='img-fluid' src="../../assets/home-equity-line.jpg" alt="Self-Employed Mortgage Services" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className='home-purchase-page-text self-mortgage-page-text'>
                      <h2>Home Equity Line Of Credit</h2>
                          <div className='small-image'><img className='img-fluid' src="../../assets/small-title-border.png" alt="Sunlite Mortgage" /></div>
                          <p>A home equity line of credit (HELOC) allows you to borrow funds against the value of your home. A HELOC is a secured loan with your home equity as collateral.</p>
                          <p>It can be used for any type of investment or purchase. It is more flexible than most other loans and allows you to borrow any amount up to your limit. You only pay interest on the amount withdrawn and can choose when and how you repay the loan without additional penalties.</p>
                    </div>           
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default HomePurchaseIntro