import React from 'react';
import InvestmentPropertyIntor from '../component/investment-property/home-purchase-intro';
import InvestmentPropertyText from '../component/investment-property/mortgage-refinancing';
import ConnectMortgageExperts from '../component/investment-property/connect-mortgage-experts';
import SliderMortgageSolutions from '../component/slider-mortgage-solutions';
import ContactBtn from '../component/contact-btn';
// import Footer from '../component/footer';
// import Header from '../component/header';


const InvestmentProperty = () => {

    React.useEffect(()=>{
        document.title = "Investment Property Mortgage Services Toronto"
    },[]);

    return (
        <>
            <InvestmentPropertyIntor />
            <InvestmentPropertyText />
            <ConnectMortgageExperts />
            <SliderMortgageSolutions/>
            <ContactBtn/>
        </>
    );
}

export default InvestmentProperty;